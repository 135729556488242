<template>
    <div v-if="isLoading" class="l-stack l-gap-3 l-padded">
        <Spinner size="medium" line-fg-color="#000" :speed="1" />
    </div>

    <div v-else class="l-settings-view l-stack l-gap-5 l-padded">
        <div class="l-stack l-gap-2">
            <h3 class="t-title">{{ $t('user_data') }}</h3>
            <form-input
                v-model="$v.firstName.$model"
                :label="$t('firstName')"
                :required="true"
                :show-required-error="!$v.firstName.required"
            />

            <form-input
                v-model="$v.lastName.$model"
                :label="$t('lastName')"
                :required="true"
                :show-required-error="!$v.lastName.required"
            />

            <label>
                <div class="form-label l-inline required">
                    {{ $t('language') }}
                </div>

                <multiselect
                    v-model="language"
                    :options="languages"
                    label="label"
                    :multiple="false"
                    track-by="value"
                    :show-labels="false"
                    :close-on-select="true"
                    :hide-selected="true"
                    :searchable="false"
                    open-direction="below"
                />
            </label>
        </div>

        <div class="l-stack l-gap-2">
            <h3 class="t-title">{{ $t('notifications') }}</h3>
            <form-multi-input
                v-model="$v.emailRecipients.$model"
                :label="$t('emailRecipients')"
                placeholder="mail@host.ch"
            >
                <template slot="error">
                    <div
                        v-if="
                            $v.emailRecipients.$anyError &&
                                $v.emailRecipients.$dirty
                        "
                        class="form-error"
                    >
                        {{ $t('notValidEmail') }}
                    </div>
                </template>
            </form-multi-input>

            <form-multi-input
                v-model="$v.smsRecipients.$model"
                :label="$t('smsRecipient')"
                :info-text="$t('smsDisclaimer')"
                placeholder="+41 79 123 45 67"
            >
                <template slot="error">
                    <div
                        v-if="
                            $v.smsRecipients.$anyError &&
                                $v.smsRecipients.$dirty
                        "
                        class="form-error"
                    >
                        {{ $t('notValidPhone') }}
                    </div>

                    <div v-if="serverErrors.sms_recipients">
                        <div
                            v-for="(error,
                            key,
                            index) in serverErrors.sms_recipients"
                            :key="index"
                            class="form-error"
                        >
                            <template v-for="e in error">{{ e }}</template>
                        </div>
                    </div>
                </template>
            </form-multi-input>
        </div>

        <div v-if="$v.$anyError" class="form-error">{{ $t('saveError') }}</div>

        <FormNavigation @save="save" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Spinner from 'vue-simple-spinner'
import Multiselect from 'vue-multiselect'
import { required, helpers, email } from 'vuelidate/lib/validators'

import FormNavigation from '../FormNavigation'
import FormInput from '../FormInput'
import FormMultiInput from '../FormMultiInput'
import httpHelper from '../../utils/modules/http.helper'

const phoneNumber = helpers.regex('phone', /^\+[0-9\s]+$/)

export default {
    name: 'SettingsView',
    components: {
        FormNavigation,
        FormInput,
        Multiselect,
        Spinner,
        FormMultiInput,
    },
    props: {},
    data() {
        return {
            languages: [
                { label: this.$t('german'), value: 'de' },
                { label: this.$t('english'), value: 'en' },
                { label: this.$t('italian'), value: 'it' },
            ],
            language: null,

            firstName: '',
            lastName: '',
            smsRecipients: '',
            emailRecipients: [],

            isLoading: true,
            serverErrors: {},
        }
    },
    computed: {
        ...mapGetters('authentication', ['getCurrentUserId']),
    },
    validations() {
        return {
            firstName: {
                required,
            },
            lastName: {
                required,
            },
            // use as soon as api is ready
            emailRecipients: {
                $each: {
                    email,
                },
            },
            smsRecipients: {
                $each: {
                    phoneNumber,
                },
            },
        }
    },
    mounted() {
        this.isLoading = true
        this.userId = this.getCurrentUserId

        Promise.resolve(this.loadData()).then(() => {
            this.isLoading = false
        })
    },
    methods: {
        ...mapActions('authentication', ['loadUserInfo']),
        async loadData() {
            await httpHelper.get('/users/' + this.userId + '/').then(res => {
                this.firstName = res.data.first_name
                this.lastName = res.data.last_name
                this.language = this.languages.find(
                    ({ value }) => value === res.data.language
                )
            })

            await httpHelper
                .get('/users/' + this.userId + '/alertsettings/')
                .then(res => {
                    this.emailRecipients = res.data.email_recipients || []
                    this.smsRecipients = res.data.sms_recipients || []
                })
        },
        async saveData() {
            this.serverErrors = {}

            return Promise.all([
                httpHelper.patch('/users/' + this.userId + '/', {
                    first_name: this.firstName,
                    last_name: this.lastName,
                    language: this.language.value,
                }),
                httpHelper.put('/users/' + this.userId + '/alertsettings/', {
                    user: this.userId,
                    enabled: true,
                    sms_recipients: this.smsRecipients,
                    email_recipients: this.emailRecipients,
                }),
            ]).catch(error => {
                console.log(error)
                this.serverErrors = Object.assign(
                    this.serverErrors,
                    error.response.data
                )
                this.isLoading = false
                return Promise.reject()
            })
            /*
                await .catch((error) => {
                    console.log(error.response)
                    this.serverErrors = Object.assign(this.serverErrors, error.response.data)
                });

                await .catch((error) => {
                    console.log(error.response)
                    this.serverErrors = Object.assign(this.serverErrors, error.response.data)
                });
*/
        },
        async save() {
            if (this.isValid()) {
                this.isLoading = true

                await this.saveData()
                await this.loadUserInfo()

                this.isLoading = false

                this.$router.push('/map', () => {
                    this.$router.go()
                })
            }
        },
        isValid() {
            this.$v.$touch()
            return !this.$v.$invalid
        },
    },
}
</script>

<i18n>
    {
        "en": {
            "user_data": "User",
            "firstName": "First Name",
            "lastName": "Last Name",
            "language": "Language",
            "german": "German",
            "english": "English",
			"italian": "Italian",
            "notifications": "Notifications",
            "smsRecipient": "Send SMS notifications to this numbers",
            "smsDisclaimer": "Additional charges are incurred when using this service.",
            "emailRecipients": "Send notifications to these e-mail addresses",
            "emailRecipientsInfo": "Multiple email addresses can be separated by commas.",
            "saveError": "Not all required fields are correctly filled out.",
            "notValidEmail": "Not all the e-mail-addresses are valid"
        },
        "de": {
            "user_data": "Benutzer",
            "firstName": "Vorname",
            "lastName": "Nachname",
            "language": "Sprache",
            "german": "Deutsch",
            "english": "Englisch",
			"italian": "Italienisch",
            "notifications": "Benachrichtigungen",
            "smsRecipient": "Nummern für SMS Benachrichtigungen",
            "smsDisclaimer": "Für die Nutzung dieser Dienstleistung fallen zusätzliche Kosten an.",
            "emailRecipients": "Benachrichtigungen an diese E-Mail Adressen schicken",
            "emailRecipientsInfo": "Mehrere E-Mail Adressen können kommasepariert erfasst werden.",
            "saveError": "Es sind nicht alle benötigten Felder korrekt ausgefüllt.",
            "notValidPhone": "Diese Telefonnummer ist ungültig. Bitte international mit z.B. +41 eingeben.",
            "notValidEmail": "Nicht jede eingegebene E-Mail-Adresse ist gültig."
        },
		"it": {
            "user_data": "Utente",
            "firstName": "Prenome",
            "lastName": "Cognome",
            "language": "Lingue",
            "german": "Tedesco",
            "english": "Inglese",
			"italian": "Italiano",
            "notifications": "Notificazione",
            "smsRecipient": "Invia SMS al seguente numeri SMS",
            "smsDisclaimer": "Ci sono costi aggiuntivi per l'utilizzo di questo servizio.",
            "emailRecipients": "Invia notifiche al seguente indirizzi Email",
            "emailRecipientsInfo": "Mehrere E-Mail Adressen können kommasepariert erfasst werden.",
            "saveError": "Es sind nicht alle benötigten Felder korrekt ausgefüllt.",
            "notValidPhone": "Diese Telefonnummer ist ungültig. Bitte international mit z.B. +41 eingeben.",
            "notValidEmail": "Nicht jede eingegebene E-Mail-Adresse ist gültig."
        }
    }
</i18n>

<style scoped></style>
